/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../interfaces.ts' />
/// <reference path='../global.ts' />

class GsapElement implements Igsap {
	public initialized: boolean = false;
	public shown: boolean = false;
	public name: string = 'GsapElement';

	/** In flow timeline stops and waing for next step*/
	public flowInWainting = false;

	/** Main TwenMaxTimeline for animation based on object state */
	protected flowTimeline: TimelineMax;

	/** Main TwenMaxTimeline for animation based on object state */
	protected flowStepCouter: number = 0;

	/** Max deep off queue of animations step */
	protected flowCouterMaxValue: number = -1;

	/** Calback debug mode */
	protected debug = false;

	constructor() {

	}

	public Resize() {
		if (this.flowTimeline)
			this.flowTimeline.clear();
		this.flowStepCouter = 0

		let that = this;
		this.flowTimeline = new TimelineMax({ onStart: that.OnFlowInit.bind(this), onComplete: that.OnFlowStoped.bind(this) });
	}

	protected FlowContinue(nextFlow: any) {
		if (this.flowTimeline == null) { 
			console.error("flow is null")
			return;
		}
		this.flowTimeline
			.addCallback(this.onFlowStepStart.bind(this), "+=0")
			.add(nextFlow)
			.addCallback(this.onFlowStepEnd.bind(this), "+=0")

		this.flowStepCouter++;
		//fast up
		if (this.flowStepCouter > 1) {
			TweenMax.to(this.flowTimeline, .2, { timeScale: 1.3 + this.flowStepCouter * .33 })
		}
		console.log(this.flowStepCouter);
	}

	protected OnFlowInit() {
		if (g.debug)
			console.log("OnFlowInit");
	}

	protected OnFlowContinue() {
		this.flowInWainting = false;

		if (g.debug)
			console.log("OnFlowStarting");
	}

	protected onFlowStepStart() {
		if (this.flowInWainting)
			this.OnFlowContinue();

		if (this.debug)
			console.log("onStepStart");
	}

	protected onFlowStepEnd() {
		this.flowStepCouter--;

		if (this.flowStepCouter == 1) {
			TweenMax.to(this.flowTimeline, .4, { timeScale: 1 })
		}

		if (this.debug)
			console.log("onStepEnd");
	}

	protected OnFlowStoped() {
		this.flowInWainting = true;

		if (this.debug)
			console.log("OnFlowStoped - couter = " + this.flowStepCouter);
	}

	public BlockNextAnimation(): boolean {
		return !(this.flowCouterMaxValue == -1 || this.flowStepCouter < this.flowCouterMaxValue)
	}
}