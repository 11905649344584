/// <reference path='../patterns/gsap-element.ts' />

const NavigationControl_ELEMENT_SELECTOR = 'nav';

class NavigationControl extends GsapElement {
	element: JQuery;
	ul: JQuery;
	menuItems: any;

	constructor() {
		super();
		//this.element = $(NavigationControl_ELEMENT_SELECTOR);
		if (this.element.length) {
			this.name = 'NavigationControl';
			this.flowCouterMaxValue = 5;

			this.ul = this.element.find("ul");
			this.menuItems = this.ul.find("li");


			// bindings
			g.body.on('click', '#hamburger', this.Toggle.bind(this));
			//g.body.on('click', 'footer .bar', function(){let t = $(this)});
		}
	}

	public Init(): any {
		this.initialized = true;
	}

	public Show(timing: number = 1) {
		if (super.BlockNextAnimation()) return

		this.shown = true;
		g.html.addClass("open");
		var timeline = new TimelineMax();

		this.FlowContinue(
			timeline
				.to(this.ul, .3 * timing, {
					autoAlpha: 1,
					y: "0%"
				})
				.staggerTo(this.menuItems, .7 * timing, {
					autoAlpha: 1,
					x: 0
				}, .2 * timing)
		);
	}

	public Hide(timing: number = 1) {
		if (super.BlockNextAnimation()) return
		this.shown = false;
		g.html.removeClass("open");
		var timeline = new TimelineMax();

		this.FlowContinue(
			timeline
				.staggerTo(this.menuItems, .5 * timing, {
					autoAlpha: 0,
					x: -50
				}, .2 * timing)
				.to(this.ul, .3 * timing, {
					autoAlpha: 0,
					y: "-100%"
				})
		);
	}

	public Toggle() {
		if (this.shown) {
			this.Hide();
		}
		else {
			this.Show();
		}
	}

	public Resize() {
		if (!this.initialized) return false;
		super.Resize();

		if (this.shown) {
			this.Show(0);
		}
		else {
			this.Hide(0);
		}
	}

	//overide flow methis - see all options in gsap-element.ts
	protected OnFlowContinue() {
		super.OnFlowContinue();

		console.log("Child OnFlowContinue");
	}

	protected OnFlowStoped() {
		super.OnFlowStoped();
		console.log("---- Child OnFlowStoped");
	}

	private onFlowStepEnds = () => {
		console.log("Child OnFlowStepEnds");
	}
}