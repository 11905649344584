/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../interfaces.ts' />

const PictureControl_OBJECTFIT_SUPPORT = (('objectFit' in document.documentElement.style === true));

class PictureControl implements Igsap {
	initialized: boolean = false;
	shown: boolean = false;
	name: string = 'PictureControl';
	img: HTMLElement;

	constructor(private element: HTMLElement, id: number) {
		this.img = element.querySelectorAll('img')[0] as HTMLElement;
		this.name += id;
		if (!PictureControl_OBJECTFIT_SUPPORT) {
			this.img.style.display = 'none';
			this.element.style.backgroundImage = 'url(' + this.img.getAttribute("src") + ')';

			// setting background-size from object-fit
			//typeof getComputedStyle !== 'undefined' ? this.element.style.backgroundSize = this.img.currentStyle['object-fit'] : this.element.style.backgroundSize = window.getComputedStyle(this.img, null).getPropertyValue('object-fit');

		}

		this.Resize();
	}

	public Init(): any {
		this.initialized = true;
	}
	public Resize() {
		if (!this.initialized) return false;
	}
}


