/// <reference path='../patterns/gsap-element.ts' />
/// <reference path='../interfaces.ts' />



class FlashArrowsControl implements Igsap {
	arrows: JQuery;
	shown = true;
	initialized = false;
	name = "";
	reverse = false;
	stagger = .2;
	constructor(private element: JQuery) {
		if (this.element.length) {
			this.name = 'FlashArrowsControl' + g.GetUniqId();
			if (this.element.hasClass("reverse")) {
				this.reverse = true;
				this.stagger = this.stagger *  -1;
			}
			// bindings
			//g.body.on('click', 'footer .bar', this.ToggleFooter.bind(this));
			//g.body.on('click', 'footer .bar', function(){let t = $(this)});
		}
	}

	public Init(): any {
		this.initialized = true;
		this.arrows = this.element.find("span");
		this.Show();
	}

	public Show(timing: number = 1): any {
		var timeline = new TimelineMax({ repeat: -1 , repeteDelay: .4});

		timeline
			.staggerTo(this.arrows, .4 * timing, { color: "#000000", autoAlpha: .7 }, this.stagger)
			.staggerTo(this.arrows, .4 * timing, { color: "#1da1f2",  autoAlpha: 1 }, this.stagger, "-.4")
	}


	public Resize() {
		if (!this.initialized) return false;

	}
}