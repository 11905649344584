/// <reference path="definition/greensock.d.ts" />
/// <reference path="definition/jquery.d.ts" />
/// <reference path="definition/nette.ajax.d.ts" />

/// <reference path="elements.ts" />
/// <reference path="library.ts" />
/// <reference path="global.ts" />

$(function () {
    g.controller = new ScrollMagic.Controller();

    g.html = $("html");
    g.body = $("body");
    g.nav = $("nav");
    g.header = $("#header");

    g.height = $(window).innerHeight();
    g.realHeight = window.innerHeight;
    g.width = $(window).innerWidth();

    g.gsap = new gsapObjects();

    let h1 = $("h1");
    let h1Width = 0; // refine in resize


    resize();

    let tempHeight = 0;
    let tempWidth = 0;

    var doit: any;
    window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(resize, 100);
    };

    function resize() {
        // Get dimensions
        g.height = $(window).innerHeight();
        // Real height on safari iPhone - height is vh unit - innerHeight is 100% of window
        g.realHeight = window.innerHeight;
        g.width = g.html.outerWidth();

        // Do not respond to iOS resaizeble header
        if (tempWidth == g.width && tempHeight == g.height) {
            return false;
        }

        tempWidth = g.width
        tempHeight = g.height

        // Detect hover events
        if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || g.width < 600)) {
            g.html.addClass("hover");
            g.hover = true;
        }
        else {
            g.html.removeClass("hover");
            g.hover = false;
        }

        // Destroy colection od scenes
        // TODO: implement ScrollMagic screene in Resize function
        if (g.controller != null) {
            for (let scene of g.scene) {
                scene.destroy(true);
            }
            g.scene = [];
        }
        g.controller = new ScrollMagic.Controller();

        g.scene.push(new ScrollMagic.Scene({
            triggerElement: '#atom',
            duration: 40,
            triggerHook: 0
        }).setTween("#scroll-down", 1, { y: "30%", autoAlpha: 0 })
            .addTo(g.controller));

        g.scene.push(new ScrollMagic.Scene({
            triggerElement: "#atom",
            duration: 40,
            triggerHook: 0,
            offset: 100
        }).setTween("#scroll-top", 1, { y: "0%", autoAlpha: 1 })
            .addTo(g.controller));

        h1Width = (h1.width() - g.width) * .9;
        //Elements resize
        g.gsap.Resize();

    }

    if (g.hover) {
        g.body.on("mousemove", function (event) {
            let moveX = (event.pageX / g.width) * h1Width;
            TweenMax.to(h1, .5, { x: - moveX });
        });
    }
    else if (window.DeviceOrientationEvent) {
        // Create an event listener
        window.addEventListener('deviceorientation', function (event) {
            // Get the left-to-right tilt (in degrees).
            var tiltLR = event.gamma;

            // Get the front-to-back tilt (in degrees).
            //var titleFB = event.beta;

            // Get the direction of the device (in degrees).
            //var direction = event.alpha;

            let percent = (tiltLR + 20) / 40 ;
            if(percent > 1){
                percent = 1
            }
            else if (percent < 0 ){
                percent = 0
            }
            let moveX = percent * h1Width;
            TweenMax.to(h1, .5, { x: - moveX });
        });

    }

    g.body.on('click', '[data-scroll]', function (e) {
        // maybe hamburger menu close
        e.preventDefault();
        var $this = $(this);
        var target = $($this.attr('data-scroll'));
        var element: any = $($this.attr('data-element'));
        var attrDelay = $this.attr('data-delay');
        var attrDuration = $this.attr('data-duration');
        var attrOffset = $this.attr('data-offset');

        var delay = 0;
        var duration = .8;
        var offset = 0 // maybe nav.height()

        // if delay and duration ware set up
        if (typeof element !== typeof undefined) { element = window; }
        if (typeof attrDelay !== typeof undefined) { delay = parseInt(attrDelay); }
        if (typeof attrDuration !== typeof undefined) { duration = parseInt(attrDuration); }
        if (typeof attrOffset !== typeof undefined) { offset = parseInt(attrOffset); }

        if (g.debug) {
            if (!target.length) {
                console.error('Target element not found');
            }
        }

        var targetWithOffset = target.offset().top + offset

        TweenMax.to(element, duration, {
            delay: attrDelay,
            scrollTo: { y: targetWithOffset, autoKill: false },
            ease: Power3.easeInOut
        });
    });

    //g.body.on('click', '[data-form]', function (e) {
    //g.forms.Action($(this).attr("data-form"));
    //});

    // -----------------------------
    // ------ debug on arrows  -----
    // -----------------------------
    g.body.keydown(function (e) {
        switch (e.which) {
            case 37: // left
                break;
            case 38: // up
                break;
            case 39: // right
                break;
            case 40: // down
                break;
            default: return;
        }
    });

    $(window).load(function () {
        //resize po nacteni obrazku
        window.setTimeout(function () {
            g.gsap.AfterLoadInitialization();
            resize();
        }, 0);
    });



});

