/// <reference path='../definition/greensock.d.ts' />
/// <reference path='../definition/jquery.d.ts' />
/// <reference path='../interfaces.ts' />

const Footer_ELEMENT_SELECTOR = '';

class Footer implements Igsap {
		initialized: boolean = false;
		shown: boolean = false;
		name: string = 'Footer';
		element: JQuery;
		constructor(/*private element: JQuery*/) {

				this.element = $(Footer_ELEMENT_SELECTOR);
				if(this.element.length){
					// bindings
					//g.body.on('click', 'footer .bar', this.ToggleFooter.bind(this));
					//g.body.on('click', 'footer .bar', function(){let t = $(this)});

					//this.initialized = true;
					this.Resize();
				}
		}

		public Init(): any {
		 this.initialized = true;
		}

		public Show(timing: number): any {
				var timeline = new TimelineMax();
				//timeline.to(this.element, .3 * timing, {autoAlpha: 1, onComplete: funtion(){});
				this.shown = true;
		}

		public Hide(timing: number): any {
				var timeline = new TimelineMax();
				this.shown = false;
		}

		//public ToggleFooter () {
		//	logFunction(this, 'ToggleFooter');
		//
		//	if (this.shown) {
		//			this.Hide(this.duration);
		//	}
		//	else {
		//			this.Show(this.duration)
		//	}
		//}

		public Resize() {

				if(!this.initialized) return false;

				if (this.shown) {
						this.Show(0);
				}
				else {
						this.Hide(0);
				}

				//TODO implements Scrollmagic here

				//if (g.width > g.mobile650) {
				//     g.scene.push(new ScrollMagic.Scene({
				//         triggerElement: '#footer'
				//     }).on('enter', function () {})
				//       .on('leave', function (event) {})
				//       .addTo(g.controller));
				//}

		}
}