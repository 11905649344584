
/// <reference path="definition/jquery.d.ts" />
/// <reference path="elements.ts" />
/// <reference path='./definition/gui.d.ts' />

declare var Global: any;
var ScrollMagic: any;

class globalVariables {
  public html: JQuery;
  public body: JQuery;
  public nav: JQuery;

  public header: JQuery;

  public height: number;
  public width: number;
  public realHeight: number;

  public gsap: gsapObjects;

  public controller: any;
  public scene: any = [];

  public hover = false;

  public debug = false;
  public log = false;

  public mobile650 = 650;

  public gui: dat.GUI // dat.
  private idIterator = 0;
  constructor() {
    if(this.debug)
     this.gui = new dat.GUI();
  }
  /// Update All controlers of data.gui
  public updateDatGui() {
    if (this.debug && this.gui) {
      for (var i in this.gui.__controllers) {
        this.gui.__controllers[i].updateDisplay();
      }
    }
  }
  /// properties to global data-gui
  public AddTracking(obj: any, properties: string[]) {
    if (this.debug && this.gui) {
      for (let i = 0; i < properties.length; i++) {
        this.gui.add(obj, properties[i]);
      }
    }
  }

  public GetUniqId(){
    return this.idIterator++;
  }
}

var g: globalVariables = new globalVariables();
