/// <reference path="definition/greensock.d.ts" />
/// <reference path="definition/jquery.d.ts" />

interface Igsap {
    initialized : boolean;
    shown : boolean;
    name :string;
    Init?() :any;
    Show?(timing :number) :any;
    Hide?(timing :number) :any;
    Resize() :any;
    Action?(name :string) :any;
}
