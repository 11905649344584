/// <reference path='./elements/footer.ts' />
/// <reference path='./elements/navigation-control.ts' />
/// <reference path='./elements/picture-control.ts' />
/// <reference path='./elements/slider.ts' />
/// <reference path='./elements/flash-arrows-control.ts' />
/// <reference path='./elements/atom-control.ts' />

class gsapObjects {
    public elements: { [id: string]: Igsap; }
    constructor() {
        let that = this;
        this.elements = {};

        //this.NewObject(new Footer())

        //this.NewObject(new NavigationControl())

        Array.prototype.forEach.call(
            document.querySelectorAll("picture"),
            function (el: any, id: any) {
                that.NewObject(new PictureControl(el, id));
            }
        );


    
        Array.prototype.forEach.call(
            document.querySelectorAll(".slider-wrapper"),
            function (el: any, id: any) {
                let slider = new RootSlider(el, id)
                that.NewObject(slider);
              
                //g.gui.add(slider, 'actualSlide');
                //g.gui.add(slider, 'animation.progress');
            }
        );


        //$(".arrow-control").each(function(){
        //    that.NewObject(new FlashArrowsControl($(this)));
        //})

        // TODO: add more objects inicialization
        // and add it in to g (global class)
        that.NewObject(new AtomControl());

    }

    NewObject(obj: Igsap): Igsap {
        this.elements[obj.name] = obj;
        return obj;
    }

    GetElementByName(name: string) {
        return this.elements[name];
    }

    Resize() {
        for (var key in this.elements) {
            this.elements[key].Resize();
        }
    }

    AfterLoadInitialization() {
        for (var key in this.elements) {
            if(this.elements[key].Init != null)
                this.elements[key].Init();
        }
    }
}



