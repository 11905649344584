/// <reference path='../patterns/gsap-element.ts' />
class LineElement extends GsapElement {
	palleteY = 1;
	palleteX = 1;
	constructor(private element: JQuery, private elementSignal: JQuery, public positionX: number, public positionY: number) {
		super();
		if (this.element.length) {
			this.name = 'LineElement';

			// bindings
			//g.body.on('click', 'footer .bar', this.ToggleFooter.bind(this));
			//g.body.on('click', 'footer .bar', function(){let t = $(this)});
		}
	}

	public Init(): any {
		this.initialized = true;
	}

	public SetPallete(x: number, y: number) {
		this.palleteX = x;
		this.palleteY = y;
	}

	public Show(timing: number = 1): any {
		if (super.BlockNextAnimation()) return

		this.shown = true;
		TweenMax.to(this.element, .3 * timing, { autoAlpha: .15 , delay: Math.random() * .4})
		TweenMax.to(this.elementSignal, .3 * timing, { autoAlpha: 1 , delay: Math.random() * .4})
	}

	public Hide(timing: number = 1): any {
		if (super.BlockNextAnimation()) return

		this.shown = false;

		//var timeline = new TimelineMax({ onComplete: function () { } });
		//this.FlowContinue(
		//	timeline.to(this.element, .3 * timing, { autoAlpha: 0 })
		//);
	}


	public Resize() {

		if (!this.initialized) return false;
		super.Resize()
		let that = this;

		TweenMax.to([this.element, this.elementSignal], 0,
			{
				attr: {
					d: "M" + (this.palleteX / 2) + " " + (this.palleteY / 2)
					 + " L" + (this.palleteX *  this.positionX) + " " + (this.palleteY *  this.positionY)
				}
			}
		);


		this.elementSignal.each(function(){
			let percent  = Math.round((16 / this.getTotalLength()) * 100);
			
			that.draw(this, true, percent);
		});
		

	}

	public draw = (a: any, b: any, c: any) => {
	
		let that = this;
		let x = b == true ? 0 : 1;
		let reverse = Math.random() > .5;
		let initState = "0%";
		let startState = "0% " + c + "%";
		let endState = "100% " + (100 + c / 2) + "%";
		if (reverse) {
			initState = "100% 100%";
			startState = (100 - c) + "% 100%";
			endState = (0 - c / 2) + "% 0%";
		}
		console.log(a);
		console.log(c);
		console.log(startState);
		var tl = new TimelineMax({ onComplete: function () { that.draw(a, !b, c) } });
		tl
			.fromTo(a, Math.random() * .3 + .1, { drawSVG: initState }, { drawSVG: startState })
			.to(a, (Math.random() * 5 + 16) / c, { drawSVG: endState });
	
	}
}