/// <reference path='../patterns/gsap-element.ts' />
/// <reference path='./point.ts' />
/// <reference path='./line.ts' />

const AtomControl_ELEMENT_SELECTOR = '#atom-control';

class AtomControl extends GsapElement {
	element: JQuery;
	width: number;
	height: number;
	svg: JQuery;
	points: PointControl[] = [];


	constructor() {
		super();
		this.element = $(AtomControl_ELEMENT_SELECTOR);
		if (this.element.length) {
			let that = this;
			this.name = 'AtomControl';

			this.svg = this.element.find("svg");

			let index = 0;
			this.element.find(".point-simple").each(function () {
				that.points.push(new PointControl($(this), that.svg.find("path:nth-child(" + (index*2 + 1) + ")")));
				index++;
			});

			// bindings
			//g.body.on('click', 'footer .bar', this.ToggleFooter.bind(this));
			//g.body.on('click', 'footer .bar', function(){let t = $(this)});
		}
	}

	public Init(): any {
		this.initialized = true;
		for (let i = 0; i < this.points.length; i++) {
			this.points[i].Init();
		}
		for (let i = 0; i < this.points.length; i++) {
			this.points[i].Show();
		}
	}

	public Show(timing: number = 1): any {
		if (super.BlockNextAnimation()) return

		this.shown = true;

		//var timeline = new TimelineMax({onComplete: function(){}});
		//this.FlowContinue(
		//	timeline.to(this.element, .3 * timing, {autoAlpha: 1})
		//);
	}

	public Hide(timing: number = 1): any {
		if (super.BlockNextAnimation()) return

		this.shown = false;

		//var timeline = new TimelineMax({onComplete: function(){}});
		//this.FlowContinue(
		//	timeline.to(this.element, .3 * timing, {autoAlpha: 0})
		//);
	}

	public Resize() {
		if (!this.initialized) return false;

		//super.Resize()

		this.height = this.svg.height();
		this.width = this.svg.width();


		for (let i = 0; i < this.points.length; i++) {
			this.points[i].SetPallete(this.width, this.height);
			this.points[i].Resize();
		}
	}
}


class PointControl {
	line: LineElement;
	point: PointElement;
	palleteX = 0;
	palleteY = 0;
	positionX = 1;
	positionY = 1;

	constructor(point: JQuery, line: JQuery) {
		this.positionX = parseFloat(point.attr("data-x"));
		this.positionY = parseFloat(point.attr("data-y"));
		this.point = new PointElement(point);
		this.line = new LineElement(line, line.next(), this.positionX, this.positionY);
	}
	public SetPallete(x: number, y: number) {
		this.palleteX = x;
		this.palleteY = y;
		this.point.SetPallete(x, y)
		this.line.SetPallete(x, y)
	}

	Init() {
		this.point.Init()
		this.line.Init()	
	}
	Show(){
		this.point.Show()
		this.line.Show()	
	}

	Resize() {
		this.point.Resize()
		this.line.Resize()
	}

}