/// <reference path='../patterns/gsap-element.ts' />

class PointElement extends GsapElement {
	palleteX = 0;
	palleteY = 0;
	positionX = 1
	positionY = 1
	constructor(private element: JQuery) {
		super();
		if (this.element.length) {
			this.name = 'Point';
			this.positionX = parseFloat(this.element.attr("data-x"));
			this.positionY = parseFloat(this.element.attr("data-y"));

			// bindings
			//g.body.on('click', 'footer .bar', this.ToggleFooter.bind(this));
			//g.body.on('click', 'footer .bar', function(){let t = $(this)});
		}
	}

	public Init(): any {
		this.initialized = true;
		console.log("inti");
	}

	public SetPallete(x: number, y: number) {
		this.palleteX = x;
		this.palleteY = y;
	}

	public Show(timing: number = 1): any {
			TweenMax.to(this.element, .5 * timing, { autoAlpha: 1 , delay: Math.random() * .4})
	}

	public Hide(timing: number = 1): any {
		if (super.BlockNextAnimation()) return

		this.shown = false;

		var timeline = new TimelineMax({ onComplete: function () { } });
		this.FlowContinue(
			timeline.to(this.element, .3 * timing, { autoAlpha: 0 })
		);
	}


	size= 0;
	public Resize() {		
		if (!this.initialized) return false;
		super.Resize()
		this.size = this.element.width() / 2;

		TweenMax.to(this.element, 0 , {x: this.palleteX * this.positionX - this.size, y: this.palleteY * this.positionY - this.size});

	}
}
